.searchborder {
  input {
    border-radius: 25px;
    border: 3px solid #e5e5e5;

    &::placeholder {
      text-align: center;
      color: #999;
    }

    // animate the border color with red color
  }
}
