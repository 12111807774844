/* Basic editor styles */
.tiptap {
  :first-child {
    margin-top: 0;
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  /* List styles */
  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* Heading styles */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
  }

  h1,
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  h4,
  h5,
  h6 {
    font-size: 1rem;
  }

  /* Code and preformatted text styles */
  code {
    background-color: var(--bs-info-light);
    border-radius: 0.4rem;
    color: var(--bs-dark);
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: var(--bs-dark);
    border-radius: 0.5rem;
    color: var(--bs-white);
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid var(--bs-light);
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid var(--bs-secondary);
    margin: 2rem 0;
  }

  // MENTIONS
  .mention {
    background-color: var(--bs-primary-light);
    border-radius: 0.4rem;
    box-decoration-break: clone;
    color: var(--bs-primary);
    padding: 0.1rem 0.3rem;
    &::after {
      content: '\200B';
    }
  }
}

/* Bubble menu */
.bubble-menu {
  background-color: var(--bs-white);
  border: 1px solid var(--bs-secondary);
  border-radius: 0.7rem;
  box-shadow: var(--bs-shadow);
  display: flex;
  padding: 0.2rem;

  button {
    background-color: unset;

    &:hover {
      background-color: var(--bs-light);
    }

    &.is-active {
      background-color: var(--bs-secondary);

      &:hover {
        background-color: var(--bs-secondary);
      }
    }
  }
}
