// React vendors
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import '~prism-themes/themes/prism-shades-of-purple.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~animate.css/animate.css';
@import '~nouislider/dist/nouislider.css';
@import '~flatpickr/dist/flatpickr.css';

#root {
  display: contents;
}

.banner-background {
  position: relative;
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: center;
  background-color: black;
}

.banner-background::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%);
}

.text-shadow {
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

// MENU SIDEBARD

.small-menu-title {
  font-size: 0.8rem;
  font-weight: 500;
  margin-top: 3px;
}

// SINGLE EVENT (PUBLIC EVENT
.single-event {
  p {
    margin-bottom: 1.5rem;
  }
}

.event-content {
  p {
    margin-bottom: 1.5rem;
    line-height: 1.5em;
  }
}

// COLOR ICKER

.c-picker {
  .picker {
    position: absolute;
    right: 10px;
    top: -35px;
  }

  .swatch {
    width: 28px;
    height: 28px;
    border-radius: 5px;

    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  .popover {
    position: absolute;
    top: calc(100% + 2px);
    right: 0;
    border-radius: 9px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
}

// SEARCH

.search-bar {
  input {
    border-radius: 25px;
    border: none;

    &::placeholder {
      text-align: center;
    }

    /* or, for legacy browsers */

    &::-webkit-input-placeholder {
      text-align: center;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      text-align: center;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      text-align: center;
    }

    &:-ms-input-placeholder {
      text-align: center;
    }
  }

  > div {
    z-index: 9999;
  }

  #search-bar-list {
    top: -20px !important;
    padding-top: 20px;
    z-index: 10;
  }
}

// BOOKING TICKET
.card.status-border {
  border-top: 5px solid transparent !important; /* Set to transparent as a placeholder */

  &.success {
    border-top-color: #47be7d !important;
  }

  &.danger {
    border-top-color: #b81c2b !important;
  }
  &.warning {
    border-top-color: #ffa800 !important;
  }
  &.info {
    border-top-color: #6c34dc !important;
  }
}

// SWAL CUSTOM OVERAY
.swal2-backdrop-show.white-backdrop {
  background-color: rgba(255, 255, 255, 1);
}

// PAGINATION
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  li.page-item {
    margin: 0 5px;
    cursor: pointer;
    background-color: #f5f5f5;
    padding: 3px;

    color: #000;
    border-radius: 50%;
  }

  li.active {
    background-color: #212121;
    color: #fff;
    border-radius: 50%;
    .page-link {
      border: none;
      background: transparent;
    }
  }
}

// SCROLLABLE CONTAINER
.v-scrollable {
  overflow: auto;
}

// OTHER
.success-border {
  border-left: 5px solid var(--bs-success) !important;
}

.danger-border {
  border-left: 5px solid var(--bs-danger) !important;
}

// PATRON UPLOADER
.width-width {
  min-width: 225px;
  max-width: 225px;
}

// TIP TAP
/* Basic editor styles */
.tiptap {
  :first-child {
    margin-top: 0;
  }

  /* List styles */
  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* Heading styles */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
  }

  h1,
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  h4,
  h5,
  h6 {
    font-size: 1rem;
  }

  /* Code and preformatted text styles */
  code {
    background-color: var(--purple-light);
    border-radius: 0.4rem;
    color: var(--black);
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: var(--black);
    border-radius: 0.5rem;
    color: var(--white);
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid var(--gray-3);
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid var(--gray-2);
    margin: 2rem 0;
  }
}

.glass-pane {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
}

.banner-blur {
  mask: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
}

// CUSTOM PILL COLORS (BS5)
.pill {
  &.pill-primary {
    background-color: var(--bs-primary);
    color: var(--bs-primary-contrast);
  }

  &.pill-secondary {
    background-color: var(--bs-secondary);
    color: var(--bs-secondary-contrast);
  }

  &.pill-success {
    background-color: var(--bs-success);
    color: var(--bs-success-contrast);
  }

  &.pill-danger {
    background-color: var(--bs-danger);
    color: var(--bs-danger-contrast);
  }

  &.pill-warning {
    background-color: var(--bs-warning);
    color: var(--bs-warning-contrast);
  }

  &.pill-info {
    background-color: var(--bs-info);
    color: var(--bs-info-contrast);
  }

  &.pill-light {
    background-color: var(--bs-light);
    color: var(--bs-light-contrast);
  }

  &.pill-dark {
    background-color: var(--bs-dark);
    color: var(--bs-dark-contrast);
  }

  // active state
  &.pill-active-primary.active {
    background-color: var(--bs-primary-dark) !important;
  }

  &.pill-active-secondary.active {
    background-color: var(--bs-secondary-dark) !important;
  }

  &.pill-active-success.active {
    background-color: var(--bs-success-dark) !important;
  }

  &.pill-active-danger.active {
    background-color: var(--bs-danger-dark) !important;
  }

  &.pill-active-warning.active {
    background-color: var(--bs-warning-dark) !important;
  }

  &.pill-active-info.active {
    background-color: var(--bs-info-dark) !important;
  }

  &.pill-active-light.active {
    background-color: var(--bs-light-dark) !important;
  }

  &.pill-active-dark.active {
    background-color: var(--bs-dark) !important;
    color: var(--bs-dark-light) !important;
  }
}

// HOVER OVERLAY
.hover-overlay {
  .position-relative {
    position: relative;
  }
  .position-relative:hover .overlay {
    opacity: 1 !important;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s;
  }
}

/*
FORM BUILDER
*/
.formbuilder-separator {
  .accordion-button,
  .dragger {
    background-color: var(--bs-secondary) !important;
    border-radius: 0.25rem;
  }
}
